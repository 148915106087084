import React from 'react';

const Acrobatreader = () => {
    return (
        <div>
            <h2>CONTENT</h2>
        </div>
    );
};

export default Acrobatreader;