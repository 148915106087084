import React from 'react';

const ThunderbirdIcon = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 750 750">
                <path fill="url(#a)" d="m 314.805,154.949 h 0.06 c 22.04,-77.0499 118.08,-114.6599 215.95,-114.6599 67.63,0 128.341,21.41 169.961,55.4 -24.838,1.1712 -49.362,6.0449 -72.76,14.4599 33.63,12.5 62.519,31.73 83.929,55.52 -16.153,-2.78 -32.532,-4.019 -48.919,-3.7 40.276,58.343 61.794,127.585 61.68,198.48 0,193.3 -156.701,350 -350.001,350 -190.32,0 -349.9999,-159.35 -349.9999,-350 0,-30.11 4,-61.2 11.77,-90.36 2.04,-6.12 4.88,-11.99 8.65,-14.14 4.72,-2.69 9.02,5.33 9.71,7.94 5.1177,19.17 12.0039,37.823 20.57,55.72 -0.75,-39.96 16.32,-76.36 39.7999,-107.84 15.66,-20.99 30.18,-40.44 36.88,-96.57 0.45,-3.77 4.02,-6.48 7.63,-5.3 50.96,16.68 78.2,101.54 73.97,172.5 28.15,4.03 28.02,-25.38 28.02,-25.38 -9,-27.66 -3,-79.07 53,-102.07 z"/>
                <path fill="url(#b)" d="m 713.254,271.32 c 8.51,192.5 -149.74,358.47 -342.71,358.47 -180.65,0 -328.6792,-139.63 -342.0692,-316.85 -2.3889,16.596 -3.6452,33.334 -3.76,50.1 1.37,189.62 160.6592,347.41 349.9992,347.41 193.3,0 350,-156.7 350,-350 0,-30.8 -3.99,-60.67 -11.46,-89.13 z" opacity="0.9"/>
                <path fill="url(#c)" d="m 366.175,180.459 c -3.77,-6.67 -21.18,-16.54 -28.79,-18.26 28.8,-92.2499 175.521,-120.5699 265.321,-104.2499 37.37,6.8 83.91,27.16 98.07,37.74 -41.62,-33.99 -102.341,-55.4 -169.961,-55.4 -97.87,0 -193.91,37.61 -215.95,114.6599 h -0.16 c -56,23 -62,74.43 -53,102.08 8.64,-32.99 49.75,-73.53 104.47,-76.57 z" style={{mixBlendMode:"screen"}}/>
                <path fill="url(#d)" d="m 467.885,116.748 c -78.62,15.47 -104.31,20.53 -130.62,45.54 29.55,-78.2297 104.98,-94.0897 194.86,-58.43 -21.391,4.41 -42.805,8.71 -64.24,12.9 z"/>
                <path fill="url(#e)" d="m 43.7049,259.38 c -21.48,87.94 -4.88,191.3 92.6991,278.04 -29.05,-31.77 -64.5191,-149.07 13.75,-232.87 5.27,-5.65 14.34,-1.5 14.63,6.22 6.45,174.16 146.98,280.53 309,260.64 -50.2,-2.82 -216.22,-60.97 -92.72,-83.97 64.55,-12.03 165.76,-30.88 165.76,-121.7 0,-147.22 -113.83,-190.26 -182.86,-183.86 -47.24,4.38 -89.29,34.36 -102.23,75.13 4.97,16.07 -14.84,27.32 -28.05,25.43 4.24,-70.95 -23,-155.86 -73.97,-172.54 -3.6,-1.18 -7.18,1.53 -7.63,5.3 -6.7,56.13 -21.22,75.58 -36.87,96.57 -23.4891,31.49 -40.5591,67.88 -39.8091,107.84 -8.5659,-17.897 -15.4521,-36.55 -20.57,-55.72 -0.57,-2.16 -3.7,-8.19 -7.48,-8.47 -2.05,-0.15 -3.14,1.85 -3.65,3.96 z"/>
                <path fill="url(#f)" d="m 337.695,496.349 c 95.04,77.17 286.17,19.31 286.17,-168.32 -77.16,116.96 -175.44,197.64 -286.16,168.32 z" style={{mixBlendMode:"screen"}}/>
                <path fill="url(#g)" d="m 150.155,304.55 c 0.864,-0.967 1.953,-1.706 3.17,-2.153 1.217,-0.446 2.526,-0.586 3.81,-0.407 -70.0595,85.45 -13.549,235.52 25.231,272.39 2.17,6.14 -36.791,-25.79 -42.161,-33.26 -29.5,-25.03 -71.7795,-149.06 9.95,-236.57 z" style={{mixBlendMode:"screen"}}/>
                <path fill="url(#h)" d="m 374.705,503.419 c 95.06,0 172.13,-62.879 172.13,-140.449 0,-77.57 -77.07,-140.45 -172.13,-140.45 -81.1,0 -172.17,52.76 -172.13,142.5 0.04,138.67 146.54,218.45 271.42,206.37 -9.38,-1.09 -67.9,-4.2 -107.45,-48.94 -3.57,-4.03 -9.76,-11.07 -6.95,-15.64 2.8,-4.57 10.52,-3.4 15.1,-3.4 z"/>
                <path fill="#fff" d="m 528.085,299.15 -136.04,130.18 c -12.07,8.58 -24.92,9.2 -37.6,1.42 L 221.015,299.63 c 3.807,-6.118 8.087,-11.929 12.8,-17.38 l 14,13.1 c 35.04,32.84 63.37,59.37 103.3,93.45 18.02,15.38 23.62,15.08 41.3,0 45.68,-39 79.09,-68.5 122.52,-107.29 4.841,5.526 9.235,11.428 13.14,17.65 z" opacity="0.6"/>
                <mask id="i" width="345" height="276" x="202" y="297" maskUnits="userSpaceOnUse">
                    <path fill="#fff" d="m 546.835,362.969 c 0,77.57 -77.07,140.45 -172.13,140.45 -4.59,0 -12.3,-1.18 -15.11,3.4 -2.81,4.56 3.38,11.6 6.95,15.63 37.16,42.04 91.07,47.33 105.22,48.72 l 2.23,0.22 c -124.88,12.08 -271.38,-67.7 -271.42,-206.37 -0.131,-23.089 6.295,-45.74 18.53,-65.32 l 134.08,121.78 c 9.54,8.66 25.61,8.66 35.15,0 l 136.62,-124.09 c 12.7,19.58 19.88,41.9 19.88,65.58 z"/>
                </mask>
                <g mask="url(#i)">
                    <path fill="url(#j)" d="m 162.705,200.979 h 435.07 v 394.47 h -435.07 z" opacity="0.7"/>
                    <g filter="url(#k)">
                        <path fill="#458fcd" fillRule="evenodd" d="m 334.745,422.298 c -25.35,-26.38 -101.27,-114.03 -101.27,-114.03 l 5.87,0.26 118.86,88.77 c 8.9,6.47 21.6,6.4 30.42,-0.15 l 116.55,-88.5 6.18,-0.49 c 0,0 -73.44,85.57 -101.57,113.76 -28.13,28.19 -49.69,26.76 -75.04,0.38 z" clipRule="evenodd"/>
                    </g>
                </g>
                <path fill="#fff" d="m 398.855,152.258 c 18.42,-5.8 16.8,-24.03 16.8,-24.03 0,0 -9.21,-10.85 -27.46,-4.84 -17.08,5.63 -19.73,17.8 -19.73,17.8 0,0 9.33,17.7 30.39,11.07 z"/>
                <defs>
                    <linearGradient id="a" x1="146.185" x2="639.58502" y1="155.649" y2="615.86902" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1B91F3"/>
                        <stop offset="1" stopColor="#0B68CB"/>
                    </linearGradient>
                    <linearGradient id="d" x1="283.47501" x2="461.01501" y1="273.45801" y2="75.818298" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#0F5DB0"/>
                        <stop offset="1" stopColor="#0F5DB0" stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="f" x1="594.625" x2="512.07501" y1="416.55899" y2="619.24902" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#E247C4" stopOpacity="0"/>
                        <stop offset="1" stopColor="#E247C4" stopOpacity="0.64"/>
                    </linearGradient>
                    <linearGradient id="g" x1="82.7155" x2="155.44501" y1="234.78999" y2="527.51001" gradientUnits="userSpaceOnUse">
                        <stop offset="0.1" stopColor="#EF3ACC"/>
                        <stop offset="1" stopColor="#EF3ACC" stopOpacity="0"/>
                    </linearGradient>
                    <linearGradient id="h" x1="374.70499" x2="374.70499" y1="273.95001" y2="569.94897" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#fff"/>
                        <stop offset="0.91" stopColor="#BEE1FE"/>
                        <stop offset="1" stopColor="#96CEFD"/>
                    </linearGradient>
                    <linearGradient id="j" x1="380.245" x2="380.245" y1="441.44901" y2="565.44897" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#BCE0FD"/>
                        <stop offset="1" stopColor="#88CCFC"/>
                    </linearGradient>
                    <radialGradient id="b" cx="0" cy="0" r="1" gradientTransform="matrix(117.92992,271.44954,-260.03887,112.97261,145.274,314)" gradientUnits="userSpaceOnUse">
                        <stop offset="0.53" stopColor="#0B4186" stopOpacity="0"/>
                        <stop offset="1" stopColor="#0B4186" stopOpacity="0.45"/>
                    </radialGradient>
                    <radialGradient id="c" cx="0" cy="0" r="1" gradientTransform="matrix(-38.950414,-49.859637,82.506525,-64.454205,383.705,192.449)" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EF3ACC" stopOpacity="0"/>
                        <stop offset="1" stopColor="#EF3ACC" stopOpacity="0.64"/>
                    </radialGradient>
                    <radialGradient id="e" cx="0" cy="0" r="1" gradientTransform="matrix(200.50032,-415.91019,513.77136,247.67684,242.704,551.15)" gradientUnits="userSpaceOnUse">
                        <stop offset="0.02" stopColor="#094188"/>
                        <stop offset="0.97" stopColor="#0B4186" stopOpacity="0"/>
                    </radialGradient>
                    <filter id="k" width="341.88" height="198.416" x="201.47501" y="276.15799" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur result="effect1_foregroundBlur_106_1002" stdDeviation="16"/>
                    </filter>
                </defs>
            </svg>
        </div>
    );
};

export default ThunderbirdIcon;