export const theme = {
    colors: {
        color1: "cornflowerblue",
        color2: "darkgrey",
        color3: "white",
        color4: "black",
        color5: "lightblue",
        color6: "grey",
        color7: "transparent",
        color8: "blue",
        color9: "#ddd",
        color10: "green",
        color11: "#A129F0"
    }
}